<template lang="pug">
  b-row.mb-5
    b-col
      genders-form(:action='action')
</template>

<script>
import GendersForm from '@views/container/manager/globals/genders/Form'

export default {
  name: 'manager-genders-new',
  components: {
    GendersForm,
  },
  props: {
    action: String,
  },
}
</script>
